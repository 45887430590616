import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { ICalendarPriceList, PriceList } from '../../models';

export const featureAdapter: EntityAdapter<PriceList> =
  createEntityAdapter<PriceList>({
    selectId: (model) => model.id,
  });

export interface State extends EntityState<PriceList> {
  selectedItem: PriceList;
  calendarPriceList: ICalendarPriceList;
  checkJobsLoading: boolean;
  isLoading?: boolean;
  error?: any;
}

export const initialState: State = featureAdapter.getInitialState({
  selectedItem: null,
  calendarPriceList: null,
  checkJobsLoading: false,
  isLoading: false,
  error: null,
});
