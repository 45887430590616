import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';

import { ErrorHandlerService } from '../../core/services/error-handler.service';
import { PriceListsService } from '../../services/price-list.service';
import { NotificationService } from '../../ui/services/notification.service';

import * as fromActions from './actions';

@Injectable()
export class PriceListsStoreEffects {
  constructor(
    private dataService: PriceListsService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
    private notifications: NotificationService,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(({ properties }) =>
        this.dataService.load(properties).pipe(
          map((response) =>
            fromActions.loadSuccess({
              items: response.data,
            }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadFailure(error));
          }),
        ),
      ),
    ),
  );

  loadCalendar$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadCalendarRequest),
      mergeMap(({ properties, date_from, date_to }) =>
        this.dataService
          .loadCalendarPriceListis(properties, date_from, date_to)
          .pipe(
            map((response) =>
              fromActions.loadCalendarSuccess({
                items: response.data,
                ranges: [date_from, date_to],
              }),
            ),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(fromActions.loadFailure(error));
            }),
          ),
      ),
    ),
  );

  updateCalendar$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.UpdateCalendarActions.updateCalendarRequest),
      mergeMap((payload) => {
        return this.dataService
          .checkJobs(payload.batchJobsIDS, payload.callback)
          .pipe(
            map(() => {
              return fromActions.UpdateCalendarActions.updateCalendarSuccess();
            }),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(
                fromActions.UpdateCalendarActions.updateCalendarFailure(error),
              );
            }),
          );
      }),
    ),
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteRequest),
      switchMap(({ priceListId }) =>
        this.dataService.delete(priceListId).pipe(
          map(() => {
            this.notifications.deleteSuccess('price_list');
            return fromActions.deleteSuccess({ priceListId });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.deleteFailure(error));
          }),
        ),
      ),
    ),
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateRequest),
      switchMap(({ priceList }) =>
        this.dataService.update(priceList).pipe(
          map((response) => {
            this.notifications.updateSuccess('price_list');
            return fromActions.updateSuccess({ priceList: response.data[0] });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.updateFailure(error));
          }),
        ),
      ),
    ),
  );

  /**
   * IMPORTANT: C'è bisogno di fare l'update con i dati perchè il create non supporta l'oggetto completo
   */
  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.createRequest),
      switchMap(({ priceList, properties }) =>
        this.dataService.create(priceList, properties).pipe(
          mergeMap((response) => [
            fromActions.createSuccess({
              priceList: response.data[0],
            }),
            fromActions.updateRequest({
              priceList: {
                ...priceList,
                id: response.data[0].id,
              },
            }),
          ]),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.createFailure(error));
          }),
        ),
      ),
    ),
  );
}
