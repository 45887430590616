import { Action, createReducer, on } from '@ngrx/store';
import { get, isEmpty } from 'lodash';

import * as fromActions from './actions';
import * as fromState from './state';
import { addDays, format, parseISO } from 'date-fns';

export const reducer = createReducer(
  fromState.initialState,
  on(fromActions.loadRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.loadSuccess, (state, { items }) =>
    fromState.featureAdapter.setAll(items, {
      ...state,
      isLoading: false,
      error: null,
    }),
  ),
  on(fromActions.loadFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(fromActions.loadCalendarRequest, (state) => ({
    ...state,
    error: null,
  })),
  on(fromActions.loadCalendarSuccess, (state, { items, ranges }) => {
    let newState = Object.assign({}, state);

    let currentDate = parseISO(ranges[0]);

    let newCalendarPriceList = { ...newState.calendarPriceList };

    while (currentDate <= parseISO(ranges[1])) {
      const dateFormatted = format(currentDate, 'yyyy-MM-dd');

      if (items[dateFormatted]) {
        newCalendarPriceList = {
          ...newCalendarPriceList,
          [dateFormatted]: items[dateFormatted],
        };
      } else {
        delete newCalendarPriceList[dateFormatted];
      }

      currentDate = addDays(currentDate, 1);
    }

    return {
      ...newState,
      calendarPriceList: {
        ...newCalendarPriceList,
      },
      checkJobsLoading: false,
    };
  }),
  on(fromActions.loadCalendarFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(fromActions.deleteRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.deleteSuccess, (state, { priceListId }) =>
    fromState.featureAdapter.removeOne(priceListId, {
      ...state,
      selectedItem:
        get(state.selectedItem, 'id', null) === priceListId
          ? null
          : state.selectedItem,
      isLoading: false,
      error: null,
    }),
  ),
  on(fromActions.deleteFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(fromActions.updateRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.updateSuccess, (state, { priceList }) =>
    fromState.featureAdapter.updateOne(
      { id: priceList.id, changes: priceList },
      {
        ...state,
        isLoading: false,
        error: null,
      },
    ),
  ),
  on(fromActions.updateFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(fromActions.createRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.createSuccess, (state, { priceList }) =>
    fromState.featureAdapter.addOne(priceList, {
      ...state,
      isLoading: false,
      error: null,
    }),
  ),
  on(fromActions.createFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(fromActions.selectItem, (state, { priceListId, override }) => {
    if (state.entities[priceListId]) {
      return {
        ...state,
        selectedItem: {
          ...state.entities[priceListId],
          ...override,
        },
      };
    }

    return {
      ...state,
      selectedItem: {
        color: '#2642f2',
        label: '',
        properties: [],
      },
    };
  }),
  on(fromActions.UpdateCalendarActions.updateCalendarRequest, (state) => ({
    ...state,

    checkJobsLoading: true,
  })),
  on(fromActions.UpdateCalendarActions.updateCalendarSuccess, (state) => ({
    ...state,
    checkJobsLoading: false,
  })),
  on(
    fromActions.UpdateCalendarActions.updateCalendarFailure,
    (state, { error }) => ({
      ...state,
      checkJobsLoading: false,
      error,
    }),
  ),
  on(fromActions.resetState, () => fromState.initialState),
);

export function priceListsReducer(
  state: fromState.State | undefined,
  action: Action,
) {
  return reducer(state, action);
}
