import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { generateParamArray } from '../core/helpers/params-generator';
import { IResponseSuccess } from '../core/models/response-sucess.model';
import { ICalendarPriceList, Job, PriceList } from '../models';
import { Observable, delay, forkJoin, of, switchMap } from 'rxjs';
import { isNil } from 'lodash';

interface LoadResponse {
  data: PriceList[];
}

interface UpdateResponse {
  data: [Required<PriceList>];
}

interface CreateResponse {
  data: [Required<PriceList>];
}

@Injectable({
  providedIn: 'root',
})
export class PriceListsService {
  constructor(private http: HttpClient) {}

  load(properties: number[]) {
    return this.http.get<LoadResponse>(
      `price_lists?${generateParamArray('property_id', properties)}`,
    );
  }

  delete(priceListId: number) {
    return this.http.delete(`price_list/${priceListId}`);
  }

  update({ id, ...priceList }: Required<PriceList>) {
    return this.http.put<UpdateResponse>(`price_list/${id}`, priceList);
  }

  create(priceList: Omit<PriceList, 'id'>, properties: number[]) {
    return this.http.post<CreateResponse>(
      `price_list?${generateParamArray('property_id', properties)}`,
      priceList,
    );
  }

  loadCalendarPriceListis(
    propertiesIds: number[],
    date_form: string,
    date_to: string,
  ) {
    return this.http.get<IResponseSuccess<ICalendarPriceList>>(
      `property/calendar/price_list/${date_form}/${date_to}?${generateParamArray(
        'property_id',
        propertiesIds,
      )}`,
    );
  }

  checkJobs(jobsIDS: string[], callback: () => void) {
    return forkJoin(jobsIDS.map((jobID) => this.check(jobID, callback)));
  }

  check(
    batch_job_id: string,
    callBack?: () => void,
    jobResponse: Job = null,
  ): Observable<Job> {
    if (!isNil(batch_job_id) && !jobResponse?.finished) {
      return this.http
        .get<IResponseSuccess<Job[]>>(`general/batchjobs/${batch_job_id}`)
        .pipe(
          switchMap((response) =>
            this.check(batch_job_id, callBack, response.data[0]).pipe(
              delay(3000),
            ),
          ),
        );
    }
    if (callBack) {
      callBack();
    }

    return of(jobResponse);
  }
}
//https://api.beddy.dev/rules?property_id[]=1&property_id[]=47&property_id[]=17&property_id[]=44&lang=it
