import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';

import { ICalendarPriceList, PriceList } from '../../models';

import { featureAdapter, State } from './state';

export const getError = (state: State): any => state.error;

export const getIsLoading = (state: State): boolean => state.isLoading;

export const getCheckJobsLoading = (state: State): boolean =>
  state.checkJobsLoading;

export const getCalendar = (state: State): ICalendarPriceList =>
  state.calendarPriceList;

export const getSelectedItem = (state: State): PriceList => state.selectedItem;

export const selectState: MemoizedSelector<object, State> =
  createFeatureSelector<State>('priceLists');

export const selectAllItems: (state: object) => PriceList[] =
  featureAdapter.getSelectors(selectState).selectAll;

export const selectById = (id: string) =>
  createSelector(selectAllItems, (priceLists: PriceList[]) => {
    if (priceLists) {
      return priceLists.find((p) => p.id === +id);
    } else {
      return null;
    }
  });

export const selectError: MemoizedSelector<object, any> = createSelector(
  selectState,
  getError,
);

export const selectIsLoading: MemoizedSelector<object, boolean> =
  createSelector(selectState, getIsLoading);

export const selectSelectedItem: MemoizedSelector<object, PriceList> =
  createSelector(selectState, getSelectedItem);

export const selectCalendar: MemoizedSelector<object, ICalendarPriceList> =
  createSelector(selectState, getCalendar);

export const selectCheckJobsLoading = createSelector(
  selectState,
  getCheckJobsLoading,
);
