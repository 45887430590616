import {
  createAction,
  createActionGroup,
  emptyProps,
  props,
} from '@ngrx/store';

import { ICalendarPriceList, PriceList, PriceListToSave } from '../../models';

export const UpdateCalendarActions = createActionGroup({
  source: 'Price Lists',
  events: {
    'Update Calendar Request': props<{
      batchJobsIDS: string[];
      callback: () => void;
    }>(),
    'Update Calendar Success': emptyProps(),
    'Update Calendar Failure': props<{ error: any }>(),
  },
});

export const loadRequest = createAction(
  '[Price Lists] Load Request',
  props<{
    properties: number[];
  }>(),
);

export const loadSuccess = createAction(
  '[Price Lists] Load Success',
  props<{
    items: PriceList[];
  }>(),
);

export const loadFailure = createAction(
  '[Price Lists] Load Failure',
  props<{ error: any }>(),
);

export const loadCalendarRequest = createAction(
  '[Price Lists] Load Calendar Request',
  props<{
    properties: number[];
    date_to: string;
    date_from: string;
    batchJobIDS?: string[];
  }>(),
);

export const loadCalendarSuccess = createAction(
  '[Price Lists] Load Calendar Success',
  props<{
    items: ICalendarPriceList;
    ranges: string[];
  }>(),
);

export const loadCalendarFailure = createAction(
  '[Price Lists] Load Calendar Failure',
  props<{ error: any }>(),
);

export const deleteRequest = createAction(
  '[Price Lists] Delete Request',
  props<{ priceListId: number }>(),
);

export const deleteSuccess = createAction(
  '[Price Lists] Delete Success',
  props<{ priceListId: number }>(),
);

export const deleteFailure = createAction(
  '[Price Lists] Delete Failure',
  props<{ error: any }>(),
);

export const updateRequest = createAction(
  '[Price Lists] Update Request',
  props<{ priceList: Required<PriceListToSave> }>(),
);

export const updateSuccess = createAction(
  '[Price Lists] Update Success',
  props<{
    priceList: Required<PriceList>;
  }>(),
);

export const updateFailure = createAction(
  '[Price Lists] Update Failure',
  props<{ error: any }>(),
);

export const createRequest = createAction(
  '[Price Lists] Create Request',
  props<{
    priceList: Omit<PriceListToSave, 'id'>;
    properties: number[];
  }>(),
);

export const createSuccess = createAction(
  '[Price Lists] Create Success',
  props<{
    priceList: Required<PriceList>;
  }>(),
);

export const createFailure = createAction(
  '[Price Lists] Create Failure',
  props<{ error: any }>(),
);

export const selectItem = createAction(
  '[Price Lists] Select Item',
  props<{
    priceListId: number;
    override: Partial<PriceList>;
  }>(),
);

export const resetState = createAction('[Price Lists] Reset State');
